/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import TabContainer from "../shared/containers/Tabs";
import { useToolContext } from "./hooks/ToolContext";
import ParametersContainer from "./ParametersContainer";
import ToolFilesContainer from "./ToolFilesContainer";
import ViewToolContextContainer from "./ViewToolContextContainer";
import ViewToolHeader from "./ViewToolHeader";
import { ExtractionSchemaContextWrapper } from "../fabrk/hooks/ExtractionSchemaContext";

type Tab = {
  name: string;
  href: string;
  current: boolean;
};

export default function ViewTool() {
  const tabs = [
    { name: "Context", href: "#context", current: false },
    { name: "Parameters", href: "#parameters", current: true },
    { name: "Files", href: "#files", current: false },
  ];

  const { isFileTool } = useToolContext();

  const tabFilter = (t: Tab) => isFileTool || t?.name !== "Files";

  const [filteredTabs, setFilteredTabs] = useState<Tab[] | null>(null);

  useEffect(() => {
    setFilteredTabs(tabs.filter(tabFilter));
  }, [isFileTool]);

  return (
    <ExtractionSchemaContextWrapper>
      <div className="h-full min-h-screen bg-skin-fill py-24 sm:py-32">
        <ViewToolHeader />

        <div className="mt-16 max-w-7xl px-6 lg:px-8">
          <TabContainer
            tabs={filteredTabs && filteredTabs.length > 0 ? filteredTabs : tabs}
          >
            {(activeTab: string) => (
              <>
                {activeTab === "Context" && <ViewToolContextContainer />}
                {activeTab === "Parameters" && <ParametersContainer />}

                {activeTab === "Files" && <ToolFilesContainer />}
              </>
            )}
          </TabContainer>
        </div>
      </div>
    </ExtractionSchemaContextWrapper>
  );
}
