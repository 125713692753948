/* eslint-disable react-hooks/exhaustive-deps */
import {
  User,
  useSession,
  useSupabaseClient,
} from "@supabase/auth-helpers-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { handleSignupUser } from "../../../api/client.api";
import { getUserById } from "../../../api/user.api";
import { useAlertContext } from "../../shared/alert/alertContext";
import { identifyMixpanelUser, initMixpanel } from "../../../utils/mixpanel";

export const notProtectedSlugs = ["/signup", "/signup/success", "/login"];

function getErrorFromHash(hash: string): Record<string, string> {
  const params = new URLSearchParams(hash.slice(1)); // remove the initial '#'
  const error: Record<string, string> = {};

  error["error"] = params.get("error") || "";
  error["error_code"] = params.get("error_code") || "";
  error["error_description"] = decodeURIComponent(
    params.get("error_description") || "",
  );

  return error;
}

export const AuthContextWrapper = (props: any) => {
  const supabase = useSupabaseClient();
  const navigate = useNavigate();
  const location = useLocation();
  const session = useSession();

  initMixpanel();

  const [companyId, setCompanyId] = useState<string>("");

  const { mutate: getUserCompany } = useMutation(getUserById, {
    onSuccess: async (res) => {
      setCompanyId(res?.users_companies[0]?.company_id);
    },
  });

  const { setAlertProps, setGlobalLoading, setLoadingText } = useAlertContext();

  const [signupUser, setSignupUser] = useState<User | null>(null);
  const [loginError, setLoginError] = useState<string>("");
  const [passwordResetInstructions, setPasswordResetInstructions] =
    useState<string>("");

  async function handleLogout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("session_id");
    localStorage.removeItem("company_id");

    await supabase.auth.signOut();
    window.location.replace("/");
  }

  useEffect(() => {
    const foundToken = localStorage.getItem("access_token");
    const foundRefreshToken = localStorage.getItem("refresh_token");

    if (
      !foundToken &&
      !foundRefreshToken &&
      session?.access_token &&
      session?.refresh_token
    ) {
      localStorage.setItem("access_token", session.access_token);
      localStorage.setItem("refresh_token", session.refresh_token);
    }
  }, [session]);

  const [searchParams] = useSearchParams();
  const paramsString = searchParams.toString();

  async function signIn(data: { email: string; password: string }) {
    const { error, data: signInData } = await supabase.auth.signInWithPassword({
      email: data.email,
      password: data.password,
    });
    if (error) {
      setLoginError(error.message);
    } else {
      const finalRedirectUrl = paramsString ? `/?${paramsString}` : "/";
      navigate(finalRedirectUrl);
      getUserCompany({ id: signInData?.user?.id });
    }
  }

  const { mutate: createUserCompanyOnSignup } = useMutation(handleSignupUser, {
    onSuccess: async (res) => {
      setGlobalLoading(false);
      setAlertProps({
        message: "Account created",
        color: "green",
      });
      const finalRedirectUrl = paramsString
        ? `/signup/success?${paramsString}`
        : "/signup/success";
      navigate(finalRedirectUrl);
    },
    onError(error: Error) {
      setGlobalLoading(false);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  // Function to handle the response
  async function handleCredentialResponse(response: any) {
    const { error, data } = await supabase.auth.signInWithIdToken({
      provider: "google",
      token: response.credential,
    });

    if (error) {
      console.log(error);
    }
    if (data) {
      setSignupUser(data.user);
      setGlobalLoading(true);
      setLoadingText("Provisioning your account...");
      createUserCompanyOnSignup({
        userId: data?.user?.id as string,
      });
    }
  }

  async function signUp(data: { email: string; password: string }) {
    try {
      const { error, data: response } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
        options: {
          emailRedirectTo: `${window.location.origin}`,
        },
      });

      if (response) {
        setSignupUser(response.user);

        // This will only need to be called once on sign up. After that the user will be able to log in.
        if (response?.user?.id) {
          setGlobalLoading(true);
          setLoadingText("Provisioning your account...");
          createUserCompanyOnSignup({
            userId: response?.user?.id,
          });
        }
      }

      if (error) {
        setLoginError(
          error.message || "There was an error signing up. Please try again.",
        );
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  async function resetPassword(email: string) {
    const { error } = await supabase.auth.resetPasswordForEmail(email);

    if (error) {
      setLoginError(
        `There was an error resetting your password. Please try again.`,
      );
    } else {
      setPasswordResetInstructions(
        "Check your email for the password reset link.",
      );
    }
  }

  useEffect(() => {
    if (!session) {
      supabase.auth.getSession().then(({ data: { session } }) => {
        if (session && session?.access_token && session?.refresh_token) {
          localStorage.setItem("access_token", session.access_token);
          localStorage.setItem("refresh_token", session.refresh_token);
        } else {
          const isNotProtected = notProtectedSlugs.some((slug) =>
            location.pathname.includes(slug),
          );

          if (notProtectedSlugs.includes(location.pathname) || isNotProtected) {
            return;
          } else {
            navigate("/login");
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    const error = getErrorFromHash(window?.location?.hash);
    if (error && error?.error_description) {
      setLoginError(error?.error_description);
    }
  }, [window?.location?.hash]);

  useEffect(() => {
    setLoginError("");
  }, []);

  useEffect(() => {
    if (session) {
      getUserCompany({ id: session.user.id });
      identifyMixpanelUser(session.user.id, session?.user?.email as string);
    }
  }, [session]);

  useEffect(() => {
    if (session && companyId) {
      localStorage.setItem("company_id", companyId);
    }
  }, [companyId, session]);

  const value = {
    signIn,
    handleLogout,
    loginError,
    resetPassword,
    passwordResetInstructions,
    signUp,
    signupUser,
    setSignupUser,
    handleCredentialResponse,
    companyId,
  };
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export const AuthContext = createContext({
  signIn: (data: { email: string; password: string }) => {},
  handleLogout: () => {},
  loginError: "",
  resetPassword: (email: string) => {},
  passwordResetInstructions: "",
  signUp: (data: { email: string; password: string }) => {},
  signupUser: null as User | null,
  setSignupUser: (user: User | null) => {},
  handleCredentialResponse: (response: any) => {},
  companyId: "",
});

export const useAuthContext = () => useContext(AuthContext);
