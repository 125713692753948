import { camelCaseToTitleCase } from "../../../utils/stringManipulation";
import { useAgentContext } from "../hooks/AgentContext";
import { agentTypeIcons } from "./agentTypeToIconMap";

function ViewAgentHeaderImg() {
  const { activeAgent } = useAgentContext();
  return (
    <div className="relative overflow-hidden rounded-3xl bg-skin-base pb-9 pt-32 shadow-2xl sm:px-12 lg:max-w-lg lg:pb-8 xl:px-10 xl:pb-10">
      <img
        alt=""
        src={activeAgent?.image_path || "/images/robot.webp"}
        className="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0"
      />

      <figure className="relative isolate">
        <figcaption className="mt-6 w-fit rounded-md bg-skin-hover px-4 leading-6">
          <strong className="text-xl font-bold text-skin-accent">
            {activeAgent?.name}
          </strong>{" "}
        </figcaption>
      </figure>
      {activeAgent?.type && (
        <figure className="relative isolate">
          <figcaption className="mt-6 flex w-fit flex-row gap-2 rounded-md bg-skin-hover px-4 text-lg leading-6">
            {agentTypeIcons[activeAgent.type]}{" "}
            <strong className="font-bold capitalize text-skin-accentSecondary">
              {camelCaseToTitleCase(activeAgent?.type)}
            </strong>{" "}
          </figcaption>
        </figure>
      )}
    </div>
  );
}

export default ViewAgentHeaderImg;
