/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Checkbox, Tooltip } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { VisibilityRow } from "../../../../api/visibility.type";
import { FormDivider } from "../../../shared/dividers/FormDivider";
import { Search } from "../../../shared/icons/icons";
import { useVisibilityContext } from "../../hooks/VisibilityContext";

export function PublicVisibility({ values }: { values?: VisibilityRow }) {
  const { setUpdatedValues } = useVisibilityContext();
  const [allChecked, setAllChecked] = useState(false);

  const [checkedValue, setCheckedValue] = useState({
    view: false,
    fork: false,
  });

  useEffect(() => {
    if (values) {
      setCheckedValue({
        view: values.public_view,
        fork: values.public_fork,
      });
    }
  }, [values?.public_fork, values?.public_view]);

  useEffect(() => {
    setUpdatedValues({
      public_fork: checkedValue.fork,
      public_view: checkedValue.view,
    });
  }, [checkedValue]);

  return (
    <Card className="max-w-md p-2">
      <div className="flex flex-col gap-2">
        <div className="ml-3 flex flex-row gap-2">
          <Search className="h-6 w-6 text-skin-base" />
          <p>Public</p>
        </div>
        <FormDivider />
        <Checkbox
          label="Select all"
          color="orange"
          crossOrigin={"true"}
          onChange={(e) => {
            setAllChecked(e.target.checked);

            if (e.target.checked) {
              setCheckedValue({
                view: true,
                fork: true,
              });
            } else {
              setCheckedValue({
                view: false,
                fork: false,
              });
            }
          }}
          checked={allChecked}
          value={allChecked ? "true" : "false"}
        />
        <div>
          <Tooltip
            placement="bottom"
            content="The agent will be visible publicly on the Fabrk AI discovery page."
            className="bg-skin-hover text-skin-base"
          >
            <Checkbox
              label="View"
              color="green"
              crossOrigin={"true"}
              onChange={(e) =>
                setCheckedValue({ ...checkedValue, view: e.target.checked })
              }
              checked={checkedValue.view}
              value={checkedValue.view ? "true" : "false"}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            content="The agent can be forked by others."
            className="bg-skin-hover text-skin-base"
          >
            <Checkbox
              label="Fork"
              color="green"
              crossOrigin={"true"}
              onChange={(e) =>
                setCheckedValue({ ...checkedValue, fork: e.target.checked })
              }
              checked={checkedValue.fork}
              value={checkedValue.fork ? "true" : "false"}
            />
          </Tooltip>
        </div>
      </div>
    </Card>
  );
}
