/* eslint-disable react-hooks/exhaustive-deps */
import { ChatBubbleBottomCenterIcon } from "@heroicons/react/24/outline";
import { Card, Checkbox, Tooltip } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { VisibilityRow } from "../../../../api/visibility.type";
import { FormDivider } from "../../../shared/dividers/FormDivider";
import { useVisibilityContext } from "../../hooks/VisibilityContext";

export function DeployVisibility({ values }: { values?: VisibilityRow }) {
  const { setUpdatedValues } = useVisibilityContext();
  const [allChecked, setAllChecked] = useState(false);

  const [checkedValue, setCheckedValue] = useState({
    api: false,
    chat: false,
    sms: false,
    email: false,
  });

  useEffect(() => {
    if (values) {
      setCheckedValue({
        email: values.email,
        chat: values.chat,
        sms: values.sms,
        api: values.api,
      });
    }
  }, [values?.public_fork, values?.public_view]);

  useEffect(() => {
    setUpdatedValues({
      email: checkedValue.email,
      chat: checkedValue.chat,
      sms: checkedValue.sms,
      api: checkedValue.api,
    });
  }, [checkedValue]);

  return (
    <Card className="max-w-md p-2">
      <div className="flex flex-col gap-2">
        <Tooltip
          placement="bottom"
          content="You can set where the agent is accessible."
          className="bg-skin-hover text-skin-base"
        >
          <div className="ml-3 flex flex-row gap-2">
            <ChatBubbleBottomCenterIcon className="h-6 w-6 text-skin-base" />
            <p>Deploy</p>
          </div>
        </Tooltip>
        <FormDivider />
        <Checkbox
          label="Select all"
          color="orange"
          crossOrigin={"true"}
          onChange={(e) => {
            setAllChecked(e.target.checked);

            if (e.target.checked) {
              setCheckedValue({
                api: true,
                chat: true,
                sms: true,
                email: true,
              });
            } else {
              setCheckedValue({
                api: false,
                chat: false,
                sms: false,
                email: false,
              });
            }
          }}
          checked={allChecked}
          value={allChecked ? "true" : "false"}
        />
        <div>
          <Tooltip
            placement="bottom"
            content="The agent can be accessed via the chat page. Set this to true when using the website chat agent."
            className="bg-skin-hover text-skin-base"
          >
            <Checkbox
              label="Chat"
              color="green"
              crossOrigin={"true"}
              onChange={(e) =>
                setCheckedValue({ ...checkedValue, chat: e.target.checked })
              }
              checked={checkedValue.chat}
              value={checkedValue.chat ? "true" : "false"}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            content="The agent can be accessed vis SMS/Text."
            className="bg-skin-hover text-skin-base"
          >
            <Checkbox
              label="Sms/Text"
              color="green"
              crossOrigin={"true"}
              onChange={(e) =>
                setCheckedValue({ ...checkedValue, sms: e.target.checked })
              }
              checked={checkedValue.sms}
              value={checkedValue.sms ? "true" : "false"}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            content="The agent can be accessed via email."
            className="bg-skin-hover text-skin-base"
          >
            <Checkbox
              label="Email"
              color="green"
              crossOrigin={"true"}
              onChange={(e) =>
                setCheckedValue({ ...checkedValue, email: e.target.checked })
              }
              checked={checkedValue.email}
              value={checkedValue.email ? "true" : "false"}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            content="This opens the agent to be accessed via API. You'll need to provide the API key to access the agent."
            className="bg-skin-hover text-skin-base"
          >
            <Checkbox
              label="API"
              color="green"
              crossOrigin={"true"}
              onChange={(e) =>
                setCheckedValue({ ...checkedValue, api: e.target.checked })
              }
              checked={checkedValue.api}
              value={checkedValue.api ? "true" : "false"}
            />
          </Tooltip>
        </div>
      </div>
    </Card>
  );
}
