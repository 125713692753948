import {
  InformationCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState } from "react";
import { ToolRow } from "../../api/tool.type";
import { useAgentContext } from "../agent/hooks/AgentContext";
import AddToolModel from "../agent/settings/AddToolModel";
import { useAgentToolContext } from "../fabrk/hooks/AgentToolContext";
import GenericDialog from "../shared/dialog/GenericDialog";
import { useToolContext } from "../tools/hooks/ToolContext";

function ToolItemFooter({ tool }: { tool: ToolRow }) {
  const { agentTools: agentAgents, removeAgent } = useAgentToolContext();
  const { activeAgent } = useAgentContext();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");

  const { setSelectedTool } = useToolContext();

  const isAdded = agentAgents?.some(
    (agentAgent) => agentAgent.tool.id === tool.id,
  );

  const foundAgent = agentAgents?.find((a) => a.tool.id === tool.id);

  return (
    <div>
      <div className="-mt-px flex divide-x divide-skin-hover">
        {isAdded && (
          <div className="-ml-px flex w-0 flex-1">
            <a
              href={`/tool/${tool.id}${isAdded ? `?agentId=${foundAgent?.agent_id}` : ""}`}
              className={clsx(
                "relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-lg border border-transparent py-4 text-sm font-semibold",
                isAdded ? "hover:bg-skin-fill" : "hover:bg-skin-hover",
              )}
            >
              <PencilIcon
                aria-hidden="true"
                className="h-6 w-6 text-skin-base"
              />
            </a>
          </div>
        )}
        <div className="flex w-0 flex-1">
          <button
            onClick={() => {
              if (isAdded) {
                removeAgent({
                  toolId: tool.id as string,
                  agentId: activeAgent?.id as string,
                });
              } else {
                setDialogTitle(tool?.name);
                setSelectedTool(tool);
                setDialogOpen(!dialogOpen);
              }
            }}
            className={clsx(
              "relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-lg border border-transparent py-4 text-sm font-semibold",
              isAdded ? "hover:bg-skin-fill" : "hover:bg-skin-hover",
            )}
          >
            {isAdded ? (
              <>
                <TrashIcon className="h-6 w-6 text-red-300" />
              </>
            ) : (
              <>
                <PlusCircleIcon
                  aria-hidden="true"
                  className="h-7 w-7 rounded-full text-green-400"
                />
              </>
            )}
          </button>
        </div>

        {!isAdded && (
          <div className="-ml-px flex w-0 flex-1">
            <a
              href={`/tool/${tool.id}${isAdded ? `?agentId=${foundAgent?.agent_id}` : ""}`}
              className={clsx(
                "relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-br-lg border border-transparent py-4 text-sm font-semibold",
                isAdded ? "hover:bg-skin-fill" : "hover:bg-skin-hover",
              )}
            >
              <InformationCircleIcon
                aria-hidden="true"
                className="h-6 w-6 text-skin-base"
              />
            </a>
          </div>
        )}
      </div>
      <GenericDialog
        title={dialogTitle}
        open={dialogOpen}
        setOpen={setDialogOpen}
      >
        <AddToolModel setDialogOpen={setDialogOpen} />
      </GenericDialog>
    </div>
  );
}

export default ToolItemFooter;
