/* eslint-disable react-hooks/exhaustive-deps */
import { AssistantStreamEvent } from "openai/resources/beta/assistants";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  streamMessageEvents,
  streamPersonaMessageEvents,
} from "../../api/stream.api";
import { isMessageContentDelta } from "../../api/stream.type";

type EventStreamingContextType = {
  events: AssistantStreamEvent[];
  text: string;
  streaming: boolean;
  startStreaming: (messageId: string) => void;
  stopStreaming: () => void;
  eventSource: EventSource | null;
  setText: (text: string) => void;
  startStreamingPersona: (
    clientAgentId: string,
    personaAgentId: string,
  ) => void;
  personaText: string;
  setPersonaText: (text: string) => void;
  personaStreaming: boolean;
  personaEvents: AssistantStreamEvent[];
  setCycles: React.Dispatch<React.SetStateAction<string>>;
  cycles: string;
};

export const EventStreamingWrapper: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cycles, setCycles] = useState("3");

  const [events, setEvents] = useState<AssistantStreamEvent[]>([]);
  const [personaEvents, setPersonaEvents] = useState<AssistantStreamEvent[]>(
    [],
  );
  const [personaEventSource, setPersonaEventSource] =
    useState<EventSource | null>(null);

  const [text, setText] = useState("");
  const [personaText, setPersonaText] = useState("");
  const [streaming, setStreaming] = useState(false);
  const [personaStreaming, setPersonaStreaming] = useState(false);
  const [eventSource, setEventSource] = useState<EventSource | null>(null);

  const startStreaming = useCallback((messageId: string) => {
    if (messageId) {
      setStreaming(true);
      const source = streamMessageEvents({
        messageId,
        onEvent: (data: AssistantStreamEvent) => {
          setEvents((prevEvents) => [...prevEvents, data]);

          if (data.event === "thread.run.completed") {
            source.close();
          }

          if (data.event === "thread.message.delta") {
            if (data?.data.delta?.content && data?.data.delta?.content[0]) {
              const content = data.data.delta.content[0];

              if (isMessageContentDelta(content)) {
                setText((prevText) => prevText + content?.text?.value);
              }
            }
          }
        },
        onError: (error) => {
          console.error("Stream Error:", error);
          stopStreaming();
          source.close();
        },
        onEnd: () => {
          stopStreaming();
          console.log("Stream has ended.");
        },
      });
      setEventSource(source);
    }
  }, []);

  const startStreamingPersona = useCallback(
    (clientAgentId: string, personaAgentId: string) => {
      if (clientAgentId) {
        setPersonaStreaming(true);
        const source = streamPersonaMessageEvents({
          clientAgentId,
          personaAgentId,
          onEvent: (data: AssistantStreamEvent) => {
            setPersonaEvents((prevEvents) => [...prevEvents, data]);

            if (data.event === "thread.run.completed") {
              source.close();
            }

            if (data.event === "thread.message.delta") {
              if (data?.data.delta?.content && data?.data.delta?.content[0]) {
                const content = data.data.delta.content[0];

                if (isMessageContentDelta(content)) {
                  setPersonaText((prevText) => prevText + content?.text?.value);
                }
              }
            }
          },
          onError: (error) => console.error("Stream Error:", error),
          onEnd: () => {
            stopStreamingPersona();
            console.log("persona stream has ended.");
          },
        });
        setPersonaEventSource(source);
      }
    },
    [],
  );

  const stopStreaming = () => {
    if (eventSource) {
      eventSource.close();
    }
    setStreaming(false);
    setEventSource(null);
  };

  const stopStreamingPersona = () => {
    if (personaEventSource) {
      personaEventSource.close();
    }
    setPersonaStreaming(false);
    setPersonaEventSource(null);
  };

  return (
    <EventStreamingContext.Provider
      value={{
        eventSource,
        events,
        text,
        streaming,
        startStreaming,
        stopStreaming,
        setText,
        startStreamingPersona,
        personaText,
        personaStreaming,
        setPersonaText,
        personaEvents,
        setCycles,
        cycles,
      }}
    >
      {children}
    </EventStreamingContext.Provider>
  );
};

const EventStreamingContext = createContext<
  EventStreamingContextType | undefined
>({
  events: [],
  text: "",
  streaming: false,
  startStreaming: () => {},
  stopStreaming: () => {},
  eventSource: null,
  setText: () => {},
  startStreamingPersona: () => {},
  personaText: "",
  personaStreaming: false,
  setPersonaText: () => {},
  personaEvents: [],
  setCycles: {} as React.Dispatch<React.SetStateAction<string>>,
  cycles: "",
});

export const useEventStreaming = () => useContext(EventStreamingContext)!;
