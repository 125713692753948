import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { Button } from "@material-tailwind/react";
import { AgentType, AgentTypes } from "../../../api/agent.type";
import { FabrkTextArea } from "../../shared/forms/FabrkTextArea";
import GenericSelect from "../../shared/forms/GenericSelect";
import { FabrkInput } from "../../shared/forms/input/InputBase";
import { useAgentContext } from "../hooks/AgentContext";

type AgentNameFormValues = {
  name: string;
  goal: string;
  type?: AgentType;
  prompt: string;
};

function EditAgentName({ setIsEdit }: { setIsEdit: (value: boolean) => void }) {
  const { activeAgent, updateAgent, updatePrompt } = useAgentContext();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      name: activeAgent?.name || "",
      goal: activeAgent?.goal || "",
      type: activeAgent?.type,
      prompt: activeAgent?.system_prompt?.text || "",
    },
  });

  const onSubmit: SubmitHandler<AgentNameFormValues> = (
    data: AgentNameFormValues,
  ) => {
    const values = {
      ...(data.name && { name: data.name }),
      ...(data.goal && { goal: data.goal }),
      ...(data.type && { type: data.type }),
      id: activeAgent?.id as string,
    };

    if (data.prompt) {
      updatePrompt({
        id: activeAgent?.system_prompt?.id as string,
        text: data.prompt,
      });
    }

    updateAgent(values);

    setIsEdit(false);
    reset();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5 p-2">
          <FabrkInput
            register={register}
            errors={errors}
            label="Name"
            name="name"
            required={true}
          />
          <div className="mb-4">
            <label className="block font-medium">Type</label>
            <Controller
              render={({ field }) => {
                return (
                  <div>
                    <GenericSelect
                      values={AgentTypes}
                      setSelected={(value) => field.onChange(value)}
                      selected={field.value}
                    />
                  </div>
                );
              }}
              name="type"
              control={control}
            />
          </div>
          <FabrkInput
            register={register}
            errors={errors}
            label="Goal"
            name="goal"
            required={false}
          />
          <FabrkTextArea
            register={register}
            errors={errors}
            label="Instructions"
            name="prompt"
            required={false}
          />
        </div>
        <div className="mt-6 flex justify-end gap-2">
          <Button
            size="sm"
            variant="outlined"
            color="gray"
            onClick={() => setIsEdit(false)}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            color="green"
            fullWidth={false}
            type="submit"
            disabled={!isDirty}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditAgentName;
