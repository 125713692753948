export enum ApiEndpoints {
  message = "/message",
  reimbursement = "/reimbursement",
  client = "/client",
  prompt = "/prompt",
  agent = "/agent",
  user = "/user",
  company = "/company",
  smsContent = "/sms-content",
  feedback = "/feedback",
  companyConfig = "/company-config",
  location = "/location",
  stripe = "/stripe",
  clientAgent = "/thread",
  companyLocation = "/company-location",
  smsMetadata = "/sms-metadata",
  payout = "/payout",
  agentSmsContent = "/agent-sms-content",
  schedule = "/schedule",
  agentTool = "/agent-tool",
  companyTool = "/company-tool",
  storage = "/storage",
  font = "/font",
  flow = "/flow",
  customer = "/customer",
  usage = "/usage",
  smsUsage = "/sms-usage",
  openai = "/openai",
  testContext = "/test-context",
  conversation = "/conversation",
  smsContentMetadata = "/sms-content-metadata",
  tool = "/tool",
  emailTemplate = "/email-template",
  extractionSchema = "/extraction-schema",
  extractionSchemaAgent = "/extraction-schema-agent",
  extractionResponses = "/extraction-responses",
  usersCompanies = "/users-companies",
  preUser = "/pre-user",
  customerBalance = "/customer-balance",
  payment = "/payment",
  mergeTag = "/merge-tag",
  email = "/email",
  session = "/session",
  fileStore = "/file-store",
  action = "/action",
  agentAgentTool = "/agent-agent-tool",
  evaluation = "/evaluation",
  credential = "/credential",
  visibility = "/visibility",
}
