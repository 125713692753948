import { ApiEndpoints } from "./apiEndpoints";
import { fetchData } from "./apiHelpers";
import { CreateMessage, SendFabrkRequest, MessageRow } from "./message.type";

export function getSmsMessagesByClientAgent({
  clientAgentId,
}: {
  clientAgentId: string;
}): Promise<MessageRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.message +
    "/clientAgent/" +
    clientAgentId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData(url, request);
}

export function createFabrkMessage(values: CreateMessage): Promise<any> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.message + "/app/create";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ ...values }),
  };

  return fetchData(request.url, request);
}

export function sendFabrkSmsOrEmail(values: SendFabrkRequest): Promise<any> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.message + "/fabrk/send";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ ...values }),
  };

  return fetch(request.url, request);
}
