import {
  AgentInsert,
  AgentRow,
  AgentUpdate,
  PublicAgentRow,
} from "./agent.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { ClientAgentRow } from "./clientAgent.type";

export function getDemoAgents(): Promise<PublicAgentRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.agent + "/public";

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<PublicAgentRow[]>(url, request);
}

export function getCompanyAgents(companyId: string): Promise<AgentRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agent +
    "/company/" +
    companyId;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<AgentRow[]>(url, request);
}

export function getAgentsByUser(userId: string): Promise<AgentRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.agent + "/user/" + userId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<AgentRow[]>(url, request);
}

export function updateAgentApiCall(
  updateAgent: Partial<AgentUpdate>,
): Promise<AgentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.agent + `/${updateAgent.id}`;

  const request = {
    method: "PUT",
    body: JSON.stringify(updateAgent),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<AgentRow>(url, request);
}

export function createNewAgent(
  agent: Partial<AgentInsert> | null,
): Promise<ClientAgentRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.agent + "/create";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(agent),
  });

  return fetchData<ClientAgentRow>(url, request);
}

export function getAgentById({ id }: { id: string }): Promise<AgentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.agent + "/client/" + id;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<AgentRow>(url, request);
}

export function forkAgent({
  agentId,
  companyId,
  clientId,
}: {
  agentId: string;
  companyId: string;
  clientId: string;
}) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.agent + "/fork";

  const request = {
    method: "POST",
    body: JSON.stringify({ agentId, companyId, clientId }),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<AgentRow>(url, request);
}

export function getAgentByName(name: string): Promise<AgentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.agent + `/name/${name}`;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<AgentRow>(request.url, request);
}

export function getDefaultAgent(companyId: string): Promise<AgentRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agent +
    `/default/${companyId}`;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<AgentRow>(request.url, request);
}
