import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMessageContext } from "../fabrk/hooks/MessageContext";
import { useScreenSize } from "../fabrk/layout/hooks/ScreenSizeContext";
import MessagesContainer from "../fabrk/messageView/MessagesContainer";
import MenuContainer from "../menu/MenuContainer";
import RightIconMenu from "../menu/RightIconMenu";
import ToolsDialogMenu from "../menu/ToolsDialogMenu";
import TextAreaContainer from "../shared/forms/textArea/TextAreaContainer";

function ChatLayout({
  initialMenuType,
  isOpen,
  children,
}: {
  initialMenuType: string;
  isOpen: boolean;
  children?: React.ReactNode;
}) {
  const { agentId, lcId } = useParams();

  const { messages } = useMessageContext();

  const [menuType, setMenuType] = useState(initialMenuType);
  const [menuOpen, setMenuOpen] = useState(isOpen);
  const { width, smallScreen } = useScreenSize();

  function handleToggle(menu: string) {
    setMenuType(menu);

    if (menuOpen && menuType === menu) {
      setMenuOpen(false);
    } else if (menu === "meta") {
      if (menuType === "meta") {
        setMenuType("");
      }
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  }

  useEffect(() => {
    if (agentId && lcId && !smallScreen) {
      setMenuOpen(true);
      setMenuType("settings");
    }
  }, [agentId, lcId, smallScreen]);

  return (
    <div className="relative flex min-h-screen flex-row bg-skin-fill text-skin-base">
      <div
        className={
          messages && messages.length > 0
            ? "mb-40 flex flex-1 flex-col justify-end gap-4 p-4"
            : "mx-auto my-64 flex h-full w-full flex-col gap-20 align-middle"
        }
      >
        {messages && messages.length > 0 && (
          <MessagesContainer menuType={menuType} />
        )}
        {(!messages || messages.length === 0) && children}

        <TextAreaContainer handleToggle={handleToggle} />
      </div>

      {menuOpen && (
        <>
          {width > 960 ? (
            <MenuContainer menuType={menuType} setOpen={setMenuOpen} />
          ) : (
            <ToolsDialogMenu
              open={menuOpen && width < 960}
              setOpen={setMenuOpen}
              menuType={menuType}
            />
          )}
        </>
      )}
      <RightIconMenu handleToggle={handleToggle} menuType={menuType} />
    </div>
  );
}

export default ChatLayout;
