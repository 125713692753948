import { useState } from "react";
import FabrkMenuDivider from "../shared/dividers/FabrkMenuDivider";
import IconButton from "../shared/buttons/IconButton";
import SubmitButton from "../shared/buttons/SubmitButton";
import { TextArea } from "../shared/forms/input/InputBase";
import { EditIcon } from "../shared/icons/icons";
import { useToolContext } from "./hooks/ToolContext";

function ViewToolContextContainer() {
  const { tool, updateTool, isCompanyTool } = useToolContext();

  const [toolContext, setToolContext] = useState(tool?.context || "");

  const [editContext, setEditContext] = useState(false);

  function handleSubmitContext() {
    updateTool({ id: tool?.id as string, context: toolContext });
    setEditContext(false);
  }

  return (
    <div className="max-w-2xl">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col gap-4">
          <p className="mt-1 max-w-2xl text-sm leading-6 text-skin-base/40">
            Free text passed into this tool by the AI
          </p>
        </div>
        {isCompanyTool && (
          <IconButton
            onClick={() => {
              if (editContext) {
                setToolContext(tool?.context || "");
              }
              setEditContext(!editContext);
            }}
          >
            <EditIcon className="h-5 w-5" />
          </IconButton>
        )}
      </div>
      <FabrkMenuDivider />
      {editContext && isCompanyTool ? (
        <div className="mt-4 flex flex-col gap-4">
          <TextArea value={toolContext} setValue={setToolContext} />
          <div className="flex justify-end">
            <SubmitButton
              type="submit"
              onClick={handleSubmitContext}
              disabled={toolContext === tool?.context || toolContext === ""}
            />
          </div>
        </div>
      ) : (
        <p className="mt-4">{tool?.context || "No context listed"}</p>
      )}
    </div>
  );
}

export default ViewToolContextContainer;
