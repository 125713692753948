/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { useEffect, useState } from "react";

type Tab = {
  name: string;
  href: string;
  current: boolean;
};

// Example Tab data
// const tabs = [
//   { name: "My Account", href: "#account", current: false },
//   { name: "Company", href: "#overview", current: false },
//   { name: "Team Members", href: "#team", current: true },
//   { name: "Billing", href: "#billing", current: false },
// ];

export default function TabContainer({
  tabs,
  children,
}: {
  tabs: Tab[];
  children: (activeTab: string) => JSX.Element;
}) {
  // State for tracking the currently active tab
  const [activeTab, setActiveTab] = useState<string>(
    tabs.find((tab) => tab?.current)?.name || tabs[0].name,
  );

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      const matchingTab = tabs.find((tab) => tab.href === hash);
      if (matchingTab) {
        setActiveTab(matchingTab.name);
      }
    };

    // Set up the event listener for hash changes
    window.addEventListener("hashchange", handleHashChange);

    // Update the active tab if the hash is set on component mount
    handleHashChange();

    // Clean up the event listener
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to update the active tab */}
        <select
          id="tabs"
          name="tabs"
          value={activeTab}
          onChange={(e) => setActiveTab(e.target.value)}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-skin-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        >
          {tabs.map((tab) => (
            <option key={tab?.name}>{tab?.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <button
                key={tab?.name}
                onClick={() => setActiveTab(tab?.name)}
                className={clsx(
                  activeTab === tab?.name
                    ? "border-skin-accent text-skin-accent"
                    : "border-transparent text-skin-base hover:border-skin-accent hover:text-skin-accent",
                  "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium",
                )}
              >
                {tab?.name}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Tab Body Component */}
      <div className="mt-6">{children(activeTab)}</div>
    </div>
  );
}
