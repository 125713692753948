import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import YourAgentsListItem from "../../components/agent/discover/YourAgentsListItem";
import { useLoadCompanyAgents } from "../../components/agent/hooks/useCompanyAgents";
import LayoutContainer from "../../components/layout/LayoutContainer";
import LoadingSpinner from "../../components/shared/forms/LoadingSpinner";
import { PageHeader } from "../../components/shared/containers/Containers";
import { mixpanelTrackEvent } from "../../utils/mixpanel";

function AgentsContainer() {
  mixpanelTrackEvent("View Agents");
  const { companyAgents, isLoading } = useLoadCompanyAgents();

  return (
    <LayoutContainer>
      <div className="ml-10 h-full min-h-screen bg-skin-fill py-10 sm:py-24">
        <PageHeader title="Your Agents" subtitle="List of your agents">
          <div className="mt-10 flex items-center gap-x-6">
            <a
              href="/discover/agents"
              className="flex flex-row items-center gap-2 rounded-md px-3.5 py-2.5 text-sm font-semibold leading-6 text-skin-base hover:bg-skin-hover"
            >
              <MagnifyingGlassIcon className="h-5 w-5" />
              Explore Agents
            </a>
          </div>
        </PageHeader>
        {isLoading ? (
          <div className="h-screen">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="mx-auto mt-4 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 px-6 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 xl:max-w-none xl:grid-cols-3">
            {companyAgents
              ?.sort((c) => {
                if (c?.visibility === "public") {
                  return -1;
                } else {
                  return 1;
                }
              })
              .map((c) => <YourAgentsListItem key={c.id} agent={c} />)}
          </div>
        )}
      </div>
    </LayoutContainer>
  );
}

export default AgentsContainer;
