import { CompanyConfigRow, CompanyRow } from "./company.type";
import { SmsContentRow } from "./smsContent.type";
import { Visibility } from "./tool.type";

import { AgentToolRow } from "./agentTool.type";
import { ClientAgentRow } from "./clientAgent.type";
import { PromptRow } from "./prompt.type";

export type DeliveryMethods = "sms" | "email" | "email_sms";

export type AgentFork = {
  id: string;
  child_agent_id?: string;
  parent_agent_id?: string;
};
export type RelationCount = {
  count: string;
};

export type PublicAgentRow = {
  default?: boolean;
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  goal: string;
  company?: CompanyRow;
  system_prompt?: PromptRow;
  system_prompt_id?: string;
  company_id?: string;
  tag_line?: string;
  title?: string;
  status: string;
  type?: AgentType;
  fabrk_public?: boolean;
  agent_fork?: RelationCount[];
  client_agent?: RelationCount[];
  agent_tool?: AgentToolRow[];
  visibility: Visibility;
  slug?: string;
  image_path?: string;
};

export type AgentRow = {
  default?: boolean;
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  goal: string;
  company?: CompanyRow;
  system_prompt?: PromptRow;
  system_prompt_id?: string;
  sms_content?: SmsContentRow[];
  company_id?: string;
  tag_line?: string;
  title?: string;
  step_one_text?: string;
  company_config?: CompanyConfigRow[];
  hero_image_url?: string;
  status: string;
  follow_up_disabled?: boolean;
  title_font_weight?: number;
  paragraph_font_weight?: number;
  type?: AgentType;
  subtitle_font_weight?: number;
  trailing_msg_count: string;
  delivery_method?: DeliveryMethods;
  fabrk_public?: boolean;
  agent_fork?: AgentFork[];
  client_agent?: ClientAgentRow[];
  agent_tool?: AgentToolRow[];
  visibility: Visibility;
  image_path?: string;
  slug: string;
};

export type AgentInsert = {
  client_id: string;
  company_id: string;
  type?: AgentType;
  name?: string;
};

export type AgentUpdate = Partial<AgentInsert> & {
  id: string;
  name?: string;
  sms_content?: Partial<SmsContentRow>;
  created_at?: string;
  system_prompt?: PromptRow;
  system_prompt_id?: string;
  tag_line?: string;
  title?: string;
  step_one_text?: string;
  company_id?: string;
  status?: string;
  hero_image_url?: string;
  delivery_method?: DeliveryMethods;
  fabrk_public?: boolean;
  goal?: string;
  trailing_msg_count?: string;
  visibility?: Visibility;
};

export type AgentForPaths = {
  params: {
    Agent: string;
  };
};

export enum AgentType {
  personas = "personas",
  businessIntelligence = "businessIntelligence",
  commerce = "commerce",
  communication = "communication",
  contentAndFiles = "contentAndFiles",
  humanResources = "humanResources",
  itOperations = "itOperations",
  lifestyleAndEntertainment = "lifestyleAndEntertainment",
  marketing = "marketing",
  productivity = "productivity",
  salesAndCRM = "salesAndCRM",
  support = "support",
  websiteAndAppBuilding = "websiteAndAppBuilding",
  evaluation = "evaluation",
}

export type AgentTypeWithLabel = {
  value: AgentType;
  label: string;
};

export const AgentTypes = [
  {
    value: AgentType.personas,
    label: "Personas",
  },
  {
    value: AgentType.businessIntelligence,
    label: "Business Intelligence",
  },
  {
    value: AgentType.commerce,
    label: "Commerce",
  },
  {
    value: AgentType.communication,
    label: "Communication",
  },
  {
    value: AgentType.contentAndFiles,
    label: "Content & Files",
  },
  {
    value: AgentType.humanResources,
    label: "Human Resources",
  },
  {
    value: AgentType.itOperations,
    label: "IT Operations",
  },
  {
    value: AgentType.lifestyleAndEntertainment,
    label: "Lifestyle & Entertainment",
  },
  {
    value: AgentType.marketing,
    label: "Marketing",
  },
  {
    value: AgentType.productivity,
    label: "Productivity",
  },
  {
    value: AgentType.salesAndCRM,
    label: "Sales & CRM",
  },
  {
    value: AgentType.support,
    label: "Support",
  },
  {
    value: AgentType.websiteAndAppBuilding,
    label: "Website & App Building",
  },
];

export function mapTypeToLabel(type: AgentType): string {
  const found = AgentTypes.find((t) => t.value === type);
  return found ? found?.label : "N/A";
}

export function mapLabelToType(label: string): AgentTypeWithLabel | undefined {
  const found = AgentTypes.find((t) => t.label === label);
  return found ? found : undefined;
}
