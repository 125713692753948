/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from "react";
import { MessageRoleType } from "../../../api/message.type";
import { useEventStreaming } from "../../message/StreamMessageContext";
import { useMessageContext } from "../hooks/MessageContext";
import AssistantMessageChatItem from "./AssistantMessageChatItem";
import MessageLoadingSkeleton from "./MessageLoadingSkeleton";
import UserAndAssistantMessages from "./UserAndAssistantMessages";
import UserMessageChatItem from "./UserMessageChatItem";

function MessagesContainer({ menuType }: { menuType: string }) {
  const { loading, messages, newMessage, messageCreatedTime } =
    useMessageContext();

  const { text, personaText, personaStreaming, streaming } =
    useEventStreaming();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (loading || text) {
      containerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, text]);

  return (
    <div className="mx-auto flex w-3/4 flex-1 flex-col gap-10 overflow-y-auto bg-skin-fill px-2">
      <UserAndAssistantMessages messages={messages} menuType={menuType} />
      {streaming && !text && <MessageLoadingSkeleton />}

      {text && (
        <div className="flex flex-row">
          <AssistantMessageChatItem
            message={{
              id: newMessage?.id || "",
              content: text,
              created_at: messageCreatedTime || "",
              role: MessageRoleType.assistant,
            }}
            showMetadata={menuType === "meta"}
          />
        </div>
      )}

      {personaStreaming && !personaText && !loading && (
        <MessageLoadingSkeleton />
      )}

      {personaText && (
        <div className="ml-10 flex flex-row">
          <UserMessageChatItem
            message={{
              id: newMessage?.id || "",
              content: personaText,
              created_at: messageCreatedTime || "",
              role: MessageRoleType.user,
            }}
          />
        </div>
      )}
      <div ref={containerRef} />
    </div>
  );
}

export default MessagesContainer;
